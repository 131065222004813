import 'bootstrap';
import $ from 'jquery';

var pathLanguage = ['ru', 'en'];

function hasTouch() {
  return 'ontouchstart' in document.documentElement
         || navigator.maxTouchPoints > 0
         || navigator.msMaxTouchPoints > 0;
}

if (hasTouch()) { // remove all the :hover stylesheets
  try { // prevent exception on browsers not supporting DOM styleSheets properly
    for (var si in document.styleSheets) {
      var styleSheet = document.styleSheets[si];
      if (!styleSheet.rules) continue;

      for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
        if (!styleSheet.rules[ri].selectorText) continue;

        if (styleSheet.rules[ri].selectorText.match(':hover')) {
          styleSheet.deleteRule(ri);
        }
      }
    }
  } catch (ex) {}
}

(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
  m[i].l=1*new Date();
  for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
  k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
  (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

ym(97534983, "init", {
  clickmap:true,
  trackLinks:true,
  accurateTrackBounce:true,
  webvisor:true
});


$(".navbar-toggler").click(function() {
  $(".animated-icon").toggleClass("open");
  $("#burger-collapse").toggleClass("d-none");
  $("main").toggleClass("blur");
})

// preload theme
var preloadTheme = localStorage.getItem("theme");
if (preloadTheme) {
  document.getElementsByTagName("html")[0].setAttribute("data-bs-theme", preloadTheme);
};

// toggle theme (dark/light)
var toggleTheme = () => {
  var data = document.getElementsByTagName("html")[0];
  var theme = localStorage.getItem("theme");
  switch (theme) {
    case "dark": 
      theme = "light";
      break;
    
    case "light": 
      theme = "dark";
      break;
    
    default: 
      theme = "dark";
      break;
    
  }
  localStorage.setItem("theme", theme);
  data.setAttribute("data-bs-theme", theme);
};

var toggle = document.getElementsByClassName("toggleTheme");
Array.from(toggle).forEach(item => {
  item.addEventListener("click", toggleTheme);
});

// plan
var plan = document.getElementsByTagName("a");
Array.from(plan).forEach(item => {
  if (item.href.match("/adspect")) {
    item.addEventListener("click", function(event) {
      localStorage.setItem("plan", item.getAttribute("data"));
    });
  }
  if (item.href.match("/checkout")) {
    item.addEventListener("click", function(event) {
      localStorage.setItem("month", 1);
      localStorage.setItem("adspect", item.getAttribute("data"));
    });
  }
});

// checkout select
var checkoutSelect = (els, el) => {
  var active = !el.classList.contains("checkout-selected");
  if (active) {
    Array.from(els).forEach(item => item.classList.remove("checkout-selected"));
    el.classList.add("checkout-selected");
    localStorage.setItem("month", el.getAttribute("data"));
  }
};

var checkout = document.getElementsByClassName("checkout");
Array.from(checkout).forEach(item => {
  item.addEventListener("click", function(){
    checkoutSelect(checkout, item);
  });
});

// auth
$("#sign-up-submit").click(function(e) {
  e.preventDefault();

  var form = $("#sign-up");
  var actionUrl = '/sign_up';

  $.ajax({
    type: "POST",
    url: actionUrl,
    data: form.serialize(),
    beforeSend: function() {
      $('#error').remove();
    },
    success: function(data) {
      $(`<div id="error" class="col-4 mx-auto text-center text-white bg-success mt-3 py-3 rounded">${data.message}</div>`).insertBefore("#sign-up");
      $('#sign-up-submit').attr("disabled", "");
    },
    error: function(XMLHttpRequest, textStatus, errorThrown) {
      if (XMLHttpRequest.responseJSON) {
        $(`<div id="error" class="col-12 col-sm-4 mx-auto text-center text-white bg-danger mt-3 py-3 rounded">${XMLHttpRequest.responseJSON.message}</div>`).insertBefore("#sign-up");
      }
    }
  });
});


$("#sign-in-submit").click(function(e) {
  e.preventDefault();

  var form = $("#sign-in");
  var actionUrl = '/sign_in';

  $.ajax({
    type: "POST",
    url: actionUrl,
    data: form.serialize(),
    beforeSend: function() {
      $('#error').remove();
    },
    success: function(data) {
      var lang = window.location.pathname.split('/')[1];
      if (pathLanguage.includes(lang)) {
        window.location.href = `/${lang}/generators`;
      } else {
        window.location.href = "/generators";
      }
    },
    error: function(XMLHttpRequest, textStatus, errorThrown) {
      if (XMLHttpRequest.responseJSON) {
        $(`<div id="error" class="col-12 col-sm-4 mx-auto text-center text-white bg-danger mt-3 py-3 rounded">${XMLHttpRequest.responseJSON.message}</div>`).insertBefore("#sign-in");
      }
    }
  });
});

var signUp = () => {
  var radio = {
    "sign_up": document.getElementsByClassName("sign-up"),
    "sign_in": document.getElementsByClassName("sign-in")
  };
  $('#error').remove();
  if (!radio.sign_up[1].classList.contains('active')) {
    radio.sign_up[1].classList.add('active');
    radio.sign_in[1].classList.remove('active');
  }
  if (!radio.sign_up[0].classList.contains('show')) {
    radio.sign_up[0].classList.add("show");
    radio.sign_in[0].classList.remove("show");
  }
}

var signIn = () => {
  var radio = {
    "sign_up": document.getElementsByClassName("sign-up"),
    "sign_in": document.getElementsByClassName("sign-in")
  };
  $('#error').remove();
  if (!radio.sign_in[1].classList.contains('active')) {
    radio.sign_in[1].classList.add('active');
    radio.sign_up[1].classList.remove('active');
  }
  if (!radio.sign_in[0].classList.contains('show')) {
    radio.sign_in[0].classList.add("show");
    radio.sign_up[0].classList.remove("show");
  }
}

if (
  document.body.contains(document.getElementsByClassName("sign-up")[0]) && 
  document.body.contains(document.getElementsByClassName("sign-in")[0])
  ) {
  if (location.hash === '#sign-up') {
    document.getElementsByClassName("sign-up")[0].classList.add("show");
    document.getElementsByClassName("sign-up")[1].classList.add("active");
  } else if (location.hash === '#sign-in') {
    document.getElementsByClassName("sign-in")[0].classList.add("show");
    document.getElementsByClassName("sign-in")[1].classList.add("active");
  }
  document.getElementsByClassName("sign-up")[0].addEventListener("click", signUp);
  document.getElementsByClassName("sign-in")[0].addEventListener("click", signIn);
}

// var signInSubmit = (event) => {
//   event.preventDefault();

//   var result = $.post("/sign_in", {
//     "email": event.target[0].value,
//     "password": event.target[1].value,
//   });
//   result.fail(function(error) {
//     alert("Неправильно введён email или пароль.")
//   });
// };

// if (document.body.contains(document.getElementById("sign-in_form"))) {
//   document.getElementById("sign-in_form").addEventListener("submit", signInSubmit)
// }

var errors = {
  "en": {
    "400": "Error in the generation parameters.",
    "401": "User authorization required.",
    "402": "Subscription required.",
    "403": "PRO or Business subscription required to download this safe pages.",
    "404": "Comsign subscription required to download safe pages.",
  },
  "ru": {
    "400": "Ошибка в параметрах генерации.",
    "401": "Необходима авторизация пользователя.",
    "402": "Необходима подписка.",
    "403": "Для скачивания вайтов необходима подписка PRO или Business.",
    "404": "Для скачивания вайтов необходима подписка Comsign.",
  }
};

var warnings = {
  "en": {
    "subscription": "Comsign subscription required to download safe pages. Click here to proceed to payment.",
  },
  "ru": {
    "subscription": "Для скачивания вайтов необходима подписка Comsign. Нажмите здесь, чтобы перейти к оплате",
  }
}

var getCookies = () => document.cookie.split(';').reduce((ac, str) => Object.assign(ac, {[str.split('=')[0].trim()]: str.split('=')[1]}), {});

$("#product > select").change(function(e) {
  if ($("#product > select").val() === 'gp' || $("#product > select").val() === 'gp_v2') {
    if (!$("#target").hasClass("d-none")) {
      $("#target").addClass("d-none");
    }
    if ($("#target-gp").hasClass("d-none")) {
      $("#target-gp").removeClass("d-none");
    }
    if (!$("#target-ap").hasClass("d-none")) {
      $("#target-ap").addClass("d-none");
    }
  } else if ($("#product > select").val() === 'ap') {
    if (!$("#target").hasClass("d-none")) {
      $("#target").addClass("d-none");
    }
    if (!$("#target-gp").hasClass("d-none")) {
      $("#target-gp").addClass("d-none");
    }
    if ($("#target-ap").hasClass("d-none")) {
      $("#target-ap").removeClass("d-none");
    }
  } else {
    if ($("#target").hasClass("d-none")) {
      $("#target").removeClass("d-none");
    }
    if (!$("#target-gp").hasClass("d-none")) {
      $("#target-gp").addClass("d-none");
    }
    if (!$("#target-ap").hasClass("d-none")) {
      $("#target-ap").addClass("d-none");
    }
  }
});

$("#preview").click(function(e) {
  e.preventDefault();

  var form = $("#generate");
  var actionUrl = '/preview';

  $.ajax({
    type: "POST",
    url: actionUrl,
    data: form.serialize(),
    beforeSend: function(){
      $('#preview-container').attr("hidden", "");

      $('#preview > i').toggleClass("d-none d-flex");
      $('#preview').toggleClass("text-primary");

      $('#error').remove();
      $('#preview').attr("disabled", "");
      $('#download').attr("disabled", "");
    },
    success: function(data) {
      localStorage.setItem("seed", data[0]);
      $('#preview-image').attr("src", `data:image/jpeg;base64,${data[1]}`);
      $('#preview-image').removeAttr("hidden");

      $('#preview > i').removeClass("d-flex");
      $('#preview > i').addClass("d-none");
      $('#preview').removeClass("text-primary");

      $('#preview').removeAttr("disabled");
      $('#download').removeAttr("disabled");
    },
    error: function(XMLHttpRequest, textStatus, errorThrown) {
      var cookies = getCookies();
      switch(XMLHttpRequest.status) {
        case 400: {
          $('.infobar').append(`<div id="error" class="text-center text-white bg-danger py-3 rounded">${errors[cookies.language]["400"]}</div>`);
          break;
        }
        case 401: {
          $('.infobar').append(`<div id="error" class="text-center text-white bg-danger py-3 rounded">${errors[cookies.language]["401"]}</div>`);
          break;
        }
        case 402: {
          $('.infobar').append(`<div id="error" class="text-center text-white bg-danger py-3 rounded">${errors[cookies.language]["402"]}</div>`);
          break;
        }
        case 403: {
          $('.infobar').append(`<div id="error" class="text-center text-white bg-danger py-3 rounded">${errors[cookies.language]["403"]}</div>`);
          break;
        }
        case 404: {
          $('.infobar').append(`<div id="error" class="text-center text-white bg-danger py-3 rounded">${errors[cookies.language]["404"]}</div>`);
          break;
        }
        case 405: {
          $('.infobar').append(`<div id="error" class="text-center text-white bg-danger py-3 rounded">${errors[cookies.language]["405"]}</div>`);
          break;
        }
        default: {
          $('.infobar').append(`<div id="error" class="text-center text-white bg-danger py-3 rounded">${errors[cookies.language]["400"]}</div>`);
          break;
        }
      }

      $('#preview-container').removeAttr("hidden");
      $('#preview-loader').toggleClass("d-flex d-none");

      $('#preview > i').removeClass("d-flex");
      $('#preview > i').addClass("d-none");
      $('#preview').removeClass("text-primary");

      $('#preview').removeAttr("disabled");
      $('#download').removeAttr("disabled");

      // $("html, body").animate({ scrollTop: 0 }, "slow");
      window.scrollTo({ top: 0 });
    }
  });
});

$("#download").click(function(e) {
  e.preventDefault();

  var form = $("#generate");
  var actionUrl = '/download';

  var seed = localStorage.getItem("seed");

  $.ajax({
    type: "POST",
    url: actionUrl,
    cache: false,
    xhr:function(){
        var xhr = new XMLHttpRequest();
        xhr.responseType= 'blob'
        return xhr;
    },
    data: `${form.serialize()}&seed=${seed}`,
    beforeSend: function() {
      $('#preview-image').attr("hidden", "");

      $('#download > i').toggleClass("d-none d-flex");
      $('#download').toggleClass("text-primary");
      // $('#preview-container').attr("disabled", "");

      $('#error').remove();
      $('#preview').attr("disabled", "");
      $('#download').attr("disabled", "");
    },
    success: function(data) {
      $('#preview-container').removeAttr("hidden");

      $('#download > i').removeClass("d-flex");
      $('#download > i').addClass("d-none");
      $('#download').removeClass("text-primary");

      $('#preview').removeAttr("disabled");
      $('#download').removeAttr("disabled");

      var url = window.URL || window.webkitURL;

      const blobURL = url.createObjectURL(data);
      const a = document.createElement('a');

      a.setAttribute('href', blobURL);
      a.setAttribute('download', `${seed}.zip`);
      a.style.display = 'none';
      document.body.appendChild(a);

      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(blobURL);
    },
    error: function(XMLHttpRequest, textStatus, errorThrown) {
      var cookies = getCookies();
      switch(XMLHttpRequest.status) {
        case 400: {
          $('.infobar').append(`<div id="error" class="text-center text-white bg-danger py-3 rounded">${errors[cookies.language]["400"]}</div>`);
          break;
        }
        case 401: {
          $('.infobar').append(`<div id="error" class="text-center text-white bg-danger py-3 rounded">${errors[cookies.language]["401"]}</div>`);
          break;
        }
        case 402: {
          $('.infobar').append(`<div id="error" class="text-center text-white bg-danger py-3 rounded">${errors[cookies.language]["402"]}</div>`);
          break;
        }
        case 403: {
          $('.infobar').append(`<div id="error" class="text-center text-white bg-danger py-3 rounded">${errors[cookies.language]["403"]}</div>`);
          break;
        }
        case 404: {
          $('.infobar').append(`<div id="error" class="text-center text-white bg-danger py-3 rounded">${errors[cookies.language]["404"]}</div>`);
          break;
        }
        case 405: {
          $('.infobar').append(`<div id="error" class="text-center text-white bg-danger py-3 rounded">${errors[cookies.language]["405"]}</div>`);
          break;
        }
        default: {
          $('.infobar').append(`<div id="error" class="text-center text-white bg-danger py-3 rounded">${errors[cookies.language]["400"]}</div>`);
          break;
        }
      }

      $('#preview-container').removeAttr("hidden");
      $('#preview-loader').toggleClass("d-flex d-none");

      $('#download > i').removeClass("d-flex");
      $('#download > i').addClass("d-none");
      $('#download').removeClass("text-primary");

      $('#preview').removeAttr("disabled");
      $('#download').removeAttr("disabled");

      // $("html, body").animate({ scrollTop: 0 }, "slow");
      window.scrollTo({ top: 0 });
    }
  });
});

$('#button-eye-pass').click(function(e) {
  var input = $('input[name="password"]');
  input.attr('type') === 'password' ? input.attr('type', 'text') : input.attr('type', 'password');
});
$('#button-eye-repass').click(function(e) {
  var input = $('input[name="re_password"]');
  input.attr('type') === 'password' ? input.attr('type', 'text') : input.attr('type', 'password');
});
$('#button-addon2').click(function(e) {
  var input = $('input[name="log_password"]');
  input.attr('type') === 'password' ? input.attr('type', 'text') : input.attr('type', 'password');
});

$('#invoice_proceed').click(function(e) {
  var data = {
    "plan": localStorage.getItem('adspect'),
    "comsign_plan": localStorage.getItem('plan'),
    "months": localStorage.getItem('month')
  };
  $.ajax({
    type: "POST",
    url: "/invoice",
    data: data,
    beforeSend: function() {
      $('#invoice_proceed').addClass('text-primary');
      $('#invoice_proceed').attr('disabled', '');
      $('#invoice_proceed > i').toggleClass("d-none d-flex");
    },
    success: function(data) {
      // window.open(data, '_blank').focus();
      window.location.href = data;
    },
    error: function(XMLHttpRequest, textStatus, errorThrown) {
    }
  })
})

if ($("#percent_five").length) {
  var costs = {
    "comsign": {
      "Basic": 300,
      "Pro": 500,
      "Business": 1500
    },
    "adspect": {
      "null": 1,
      "Personal": 500,
      "Professional": 1000,
    }
  };
  var plans = {
    "comsign": localStorage.getItem("plan"),
    "adspect": localStorage.getItem("adspect"),
  };

  var summ = parseInt(costs.comsign[plans.comsign] + costs.adspect[plans.adspect]);

  var percent = {
    "five": Math.round(summ * 0.05) * 3,
    "ten": Math.round(summ * 0.1) * 6,
    "fiftheen": Math.round(summ * 0.15) * 12,
  };

  $("#percent_five").text(`${percent.five}$`);
  $("#percent_ten").text(`${percent.ten}$`);
  $("#percent_fiftheen").text(`${percent.fiftheen}$`);
}




